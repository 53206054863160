
import DateFieldMonthYear from '@/components/FormComponents/DateFieldMonthYear.vue';
import { noDateInFutureMonthYearOnly, required } from '@/helpers/validationRules';
import { ValidationFunction } from '@/helpers/validationRules.types';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import dayjs from 'dayjs';
import { PropOptions } from 'vue';
import mixins from 'vue-typed-mixins';

export default mixins(formRulesMixin).extend({
    components: { DateFieldMonthYear },
    mixins: [formRulesMixin],

    props: {
        value: {
            required: true
        } as PropOptions<string | null>
    },

    computed: {
        rules(): ValidationFunction[] {
            return [required, noDateInFutureMonthYearOnly];
        },
        inputValue: {
            get(): string | null {
                return this.value;
            },
            set(value: string) {
                this.$emit('input', value);
            }
        }
    },

    methods: {
        formatDate(): string | null {
            if (!this.inputValue) {
                return null;
            }

            return dayjs(this.inputValue, 'YYYY-MM-DD', true).format('YYYY-MM');
        },
        changeDate(value: string) {
            this.inputValue = `${value}-01`;
        }
    }
});
