
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { lookupCompanynumberMixin } from '@/mixins/lookupCompanynumberMixin';

import { vatStatuses } from '@/config/constants';

import CompanyNumberField from '@/components/FormComponents/CompanyNumberField.vue';
import TooltipVideoWrapper from '@/components/TooltipVideoWrapper.vue';
import { reportError } from '@/helpers/logging';
import { DeepNullable } from '@/helpers/types';
import { companyLookup } from '@/services/customer';
import {
    completeRegistrationWithCompanyData,
    ISchoolViewModel,
    ISocialsecretariatViewModel,
    VATType
} from 'dexxter-types';
import { PropOptions } from 'vue';
import mixins from 'vue-typed-mixins';

export default mixins(formRulesMixin, lookupCompanynumberMixin).extend({
    components: { CompanyNumberField, TooltipVideoWrapper },
    mixins: [formRulesMixin, lookupCompanynumberMixin],

    props: {
        value: {
            required: true
        } as PropOptions<DeepNullable<completeRegistrationWithCompanyData>>,
        schools: {
            required: true
        } as PropOptions<ISchoolViewModel[]>,
        socialSecretariats: {
            required: true
        } as PropOptions<ISocialsecretariatViewModel[]>,
        showHelpVideos: {
            required: false,
            default: true
        } as PropOptions<boolean>
    },

    data() {
        return {
            videoTooltips: [
                'videotooltips.completeUserRegistration.vatStatus',
                'videotooltips.completeUserRegistration.companyNumberVsVATNumber'
            ],
            vatLiabelItems: [
                {
                    value: vatStatuses.VATLIABLE,
                    label: this.$i18n.t('completeUserRegistration.VATLiable')
                },
                {
                    value: vatStatuses.NOTVATLIABLE,
                    label: this.$i18n.t('completeUserRegistration.NotVATLiable')
                },
                {
                    value: vatStatuses.VATEXEMPT,
                    label: this.$i18n.t('completeUserRegistration.VATExempt')
                }
            ] as { value: VATType; label: string }[]
        };
    },

    computed: {
        registrationData: {
            get(): DeepNullable<completeRegistrationWithCompanyData> {
                return this.value;
            },
            set(value: DeepNullable<completeRegistrationWithCompanyData>) {
                this.$emit('input', value);
            }
        },
        hasUserPickedStudentRole(): boolean {
            return this.registrationData.role === 'student';
        }
    },

    methods: {
        async lookupCompany(): Promise<void> {
            try {
                const companyNumber = this.registrationData.company.number;

                if (!companyNumber) {
                    return;
                }

                const result = await companyLookup(companyNumber);

                this.fillInMissingCompanyInfoBasedOnLookup(this.registrationData, result);
            } catch (e) {
                reportError(e);
            }
        }
    }
});
