<template>
    <div class="d-flex">
        <v-text-field
            v-mask="'##-####'"
            :value="dateString"
            hide-details="auto"
            outlined
            dense
            v-bind="$attrs"
            :rules="rules"
            :error-messages="errors"
            :error="error"
            placeholder="mm-jjjj"
            :class="{ required }"
            readonly
            @click="menu = true"
            @blur="checkValidity($event.target.value)"
        />
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" min-width="290">
            <template #activator="{ on, attrs }">
                <v-btn color="primary" icon v-bind="attrs" v-on="on"><v-icon small>mdi-calendar</v-icon></v-btn>
            </template>
            <v-date-picker
                v-show="isDatePickerVisible"
                :id="datePickerId"
                v-model="date"
                color="primary"
                type="month"
                no-title
                @input="handleInput"
            />
        </v-menu>
    </div>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
export default {
    mixins: [formRulesMixin],
    inheritAttrs: false,

    props: {
        value: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        datePickerId: {
            type: String
        },
        inputRules: {
            type: Array,
            required: false
        }
    },

    data() {
        return {
            menu: false,
            date: null,
            isDatePickerVisible: true,
            errors: []
        };
    },

    computed: {
        dateString() {
            if (isEmpty(this.value)) {
                return null;
            } else if (dayjs(this.value, 'YYYY-MM').isValid()) {
                return dayjs(this.value, 'YYYY-MM').format('MM-YYYY');
            }

            throw new Error('Invalid date');
        },
        rules() {
            if (this.inputRules) {
                return this.inputRules;
            }

            if (this.required) {
                return this.requiredRules;
            }

            return [];
        },
        error() {
            return this.errors && this.errors.length > 0;
        }
    },

    watch: {
        value(newValue) {
            this.checkValidity(newValue);
            if (dayjs(newValue, 'YYYY-MM', true).isValid()) {
                this.date = newValue;
            } else {
                this.date = dayjs().format('YYYY-MM');
            }
        }
    },

    methods: {
        checkValidity(value) {
            if (value === null || (value === '' && !this.required)) {
                // When a form gets reset and they set the value to null, the error should dissapear
                this.errors = [];
                return;
            }

            if (!dayjs(value, 'MM-YYYY', true).isValid() && !dayjs(value, 'YYYY-MM', true).isValid()) {
                this.errors = ['Dit is geen geldige datum'];
            } else {
                this.errors = [];
            }
        },
        handleInput(value) {
            this.$emit('input', value);
            if (dayjs(value, 'YYYY-MM', true).isValid()) {
                this.menu = false;
                this.errors = [];
            }
        },
        close() {
            this.menu = false;
            this.isDatePickerVisible = true;
        }
    }
};
</script>
