
import KnowsUsFromSelect from '@/components/KnowsUsFromSelect/KnowsUsFromSelect.vue';
import { vatStatuses } from '@/config/constants';
import { DeepNullable } from '@/helpers/types';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { lookupCompanynumberMixin } from '@/mixins/lookupCompanynumberMixin';
import DexxterStartDateForm from '@/views/registration/completeUserRegistration/components/DexxterStartDateForm/DexxterStartDateForm.vue';
import {
    completeRegistrationWithCompanyData,
    IProfessionalactivityGetAllViewModel,
    IProfessionalactivityViewModel
} from 'dexxter-types';
import Vue, { PropOptions } from 'vue';
import { ShowFeatureProps } from './completeUserRegistrationWithCompanyNumberSubformsPart2.types';

export default Vue.extend({
    components: { DexxterStartDateForm, KnowsUsFromSelect },
    mixins: [formRulesMixin, lookupCompanynumberMixin],

    props: {
        value: {
            required: true
        } as PropOptions<DeepNullable<completeRegistrationWithCompanyData>>,
        professionalactivities: {
            required: true
        } as PropOptions<IProfessionalactivityGetAllViewModel[]>,
        showFeatures: {
            required: false,
            default: () => {
                return {
                    showPromocode: {
                        activated: true
                    }
                };
            }
        } as PropOptions<ShowFeatureProps>
    },

    data() {
        return {
            submitDialog: false,
            selectedMainProfessionalactivityId: null as null | number
        };
    },

    computed: {
        canShowPromocode(): boolean {
            if (!this.showFeatures.showPromocode) {
                return true;
            }

            return this.showFeatures.showPromocode.activated;
        },
        IBANHintText(): string {
            if (!this.showFeatures.IBAN) {
                return this.$t('completeUserRegistration.why_iban') as string;
            }

            return this.showFeatures.IBAN.customHint;
        },
        registrationData: {
            get(): DeepNullable<completeRegistrationWithCompanyData> {
                return this.value;
            },
            set(value: DeepNullable<completeRegistrationWithCompanyData>) {
                this.$emit('input', value);
            }
        },
        eligableProfessionalactivities(): IProfessionalactivityGetAllViewModel[] {
            if (!this.professionalactivities) {
                return [];
            }

            if (this.registrationData.vatLiable === vatStatuses.VATEXEMPT) {
                return this.professionalactivities.filter((_activity) => _activity.VATExemptCategory);
            }

            return this.professionalactivities.filter((_activity) => !_activity.VATExemptCategory);
        },
        mainActivities(): IProfessionalactivityGetAllViewModel[] {
            if (!this.eligableProfessionalactivities) {
                return [];
            }
            return [...this.eligableProfessionalactivities].sort((a, b) => (a.name > b.name ? 1 : -1));
        },
        subActivities(): IProfessionalactivityViewModel[] {
            if (!this.selectedMainProfessionalactivityId) {
                return [];
            }

            const found = this.eligableProfessionalactivities.find(
                (activity) => activity.id === this.selectedMainProfessionalactivityId
            );

            if (!found) {
                return [];
            }

            return found.subActivities;
        }
    },

    methods: {
        handleChangeKnowsUsValue(value: string | null) {
            this.registrationData.knowsUsFrom = value;
        },
        handleSubmit() {
            this.$emit('submit');
        },
        navigateBack() {
            this.$emit('back');
        }
    }
});
